import { SET_USER_INFO } from './user-info-types'

const initialState = {
  token: '',
  flags: null,
}

export const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
