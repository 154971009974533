import {
  Suspense,
} from 'react'
import PropTypes from 'prop-types'
import { PageContainer, Loader } from 'jmt-styled-components'

export const CustomSuspense = ({
  children,
}) => (
  <Suspense
    fallback={(
      <PageContainer>
        <Loader />
      </PageContainer>
    )}
  >
    {children}
  </Suspense>
)

CustomSuspense.propTypes = {
  children: PropTypes.node.isRequired,
}
