import {
  lazy,
} from 'react'
import { CustomSuspense } from '../../components/CustomSuspense'

const LazyDoi = lazy(() => import('../doi/Doi'))

export const Doi = (props) => (
  <CustomSuspense>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LazyDoi {...props} />
  </CustomSuspense>
)
