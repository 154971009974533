import PropTypes from 'prop-types'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { fetchComponentTemplatedResources } from 'jmt-fetch-component-resources'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../utils/custom-hooks/useTranslation'

const MyProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  min-height: 50px;
  flex-shrink: 0;
`

const Content = styled.div`
  overflow-y: auto;
  flex: 1;
  max-height: 500px;

  @media (max-width: 992px) {
    max-height: 350px;
  }
`

export const MyProfileSlide = ({
  onContinue,
  data,
}) => {
  const theme = useTheme()
  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const {
    oauthUrl,
    apiUrl,
    instance,
    language,
    identityUrl,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  useEffect(() => {
    fetchComponentTemplatedResources({
      manageSubUrl: 'vmx.my-profile/vmx.component.my-profile',
      componentType: 'vue',
      version: '1.4.1',
    })
  }, [])

  useEffect(() => {
    const catchEventAfterSave = () => {
      onContinue()
    }
    window.addEventListener('my-profile.eventAfterSave', catchEventAfterSave)
    return () => {
      window.removeEventListener('my-profile.eventAfterSave', catchEventAfterSave)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <MyProfileContainer>
      <Header>
        <h3>
          {t('Conditions_Checker-User_Complete-Title')}
        </h3>
      </Header>
      <Content>
        <div
          id="my-profile"
          token={token}
          identity_url={identityUrl}
          oauth_url={oauthUrl}
          client_id=""
          // TODO: set data inner props, event-short should be name and not id
          event_module_id={data.EventModuleId}
          api_url={apiUrl}
          instance={instance}
          language={language}
          event_short={data.EventShort}
          context={data.Context}
          hide_title="true"
          color={theme.primaryColor}
          event_after_save="true"
          save_button_text={t('Conditions_Checker-User_Complete-Save_Continue_Button')}
        >
          not rendered yet
        </div>
      </Content>
    </MyProfileContainer>
  )
}

MyProfileSlide.propTypes = {
  onContinue: PropTypes.func.isRequired,
  data: PropTypes.shape({
    EventModuleId: PropTypes.number.isRequired,
    EventShort: PropTypes.string.isRequired,
    Context: PropTypes.string.isRequired,
  }).isRequired,
}
