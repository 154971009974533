import { SET_ROUTER } from './router-types'

const initialState = {
  history: [],
  // required to fix an issue when firefox tab becomes inactive after navigating to external page
  sentToExternal: false,
}

export const routerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
