import PropTypes from 'prop-types'
import { PageContainer as JMTPageContainer } from 'jmt-styled-components'
import styled from '@emotion/styled'
import { GoBackButton } from '../../GoBackButton'
import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

const StyledPage = styled(JMTPageContainer)`
  ${(p) => (p.backgroundColor ? `background-color: ${p.backgroundColor};` : '')}
`

export const PageContainer = ({ children }) => {
  const { props: templateProps } = useTemplate('PageContainer')

  return (
    <StyledPage backgroundColor={templateProps.backgroundColor}>
      <GoBackButton />
      {children}
    </StyledPage>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
