import PropTypes from 'prop-types'

import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { PageTitle } from '../../../components/base/texts/PageTitle'
import { useShallowEqualSelector } from '../../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'
import { MemberView } from '../components/MemberView'

export const MembershipRoot = ({ title }) => {
  const {
    membership,
  } = useShallowEqualSelector((state) => state.appInfoReducer)

  const { t } = useTranslation()

  if (!Object.keys(membership).length) {
    return (
      <PageContainer>
        <div>
          We have an error please try again later
        </div>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      <MemberView membership={membership} />
    </PageContainer>
  )
}

MembershipRoot.propTypes = {
  title: PropTypes.string.isRequired,
}
