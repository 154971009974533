import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FlexBox } from '../../../components/base/layouts/FlexBox'
import { Text } from '../../../components/base/texts/Text'

const ResponsiveFlexBox = styled(FlexBox)`
  @media (max-width: 595px) {
    flex-direction: column;
  }
`

const RowLabel = styled.span`
  font-size: 16px;
  line-height: 16px;
  flex: 1;
  padding: 10px 0;
  padding-right: 0.5rem;
  @media (max-width: 595px) {
    padding-bottom: 0px;
  }
`

const RowValue = styled.span`
  font-size: 16px;
  line-height: 16px;
  flex: 2;
  padding: 10px 0;
`

export const RowItem = ({
  label,
  value,
}) => (
  <ResponsiveFlexBox>
    <RowLabel>
      <Text isBold>{label}</Text>
    </RowLabel>
    <RowValue>
      {value}
    </RowValue>
  </ResponsiveFlexBox>
)

RowItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
}
