import PropTypes from 'prop-types'
import { useEffect } from 'react'
import styled from '@emotion/styled'

import { Icon } from 'jmt-styled-components'

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(5, 5, 5, 0.5);
`

const ModalContainer = styled.div`
  opacity: 1;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);
`

const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: ${(p) => p.theme.primaryColor};
  border-radius: 50px;
  padding: 10px 12px;
`

const ModalHeader = styled.div`
  padding: 15px;
  color: ${(p) => p.theme.primaryColorInvert};
  background-color: ${(p) => p.theme.primaryColor};
`

const ModalContent = styled.div`
  width: 80vw;
  max-height: 90vh;
  overflow: auto;
  border-radius: 10px;
  position: relative;
  background-color: #ffffff;
`

const ModalBody = styled.div`
  padding: 15px;
`

export const Modal = ({
  isVisible,
  onClose,
  headerContent,
  children,
}) => {
  useEffect(() => {
    const initialBodyOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = initialBodyOverflow
    }
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <ModalWrapper
      onClick={onClose}
    >
      <CloseButton onClick={onClose}>
        <Icon icon="fa-times" color="#ffffff" size="lg" />
      </CloseButton>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalContent>
          {
            headerContent && (
              <ModalHeader>{headerContent}</ModalHeader>
            )
          }
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalWrapper>
  )
}

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  headerContent: PropTypes.string,
}

Modal.defaultProps = {
  headerContent: '',
}
