import { Loader } from 'jmt-styled-components'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { fetchComponentTemplatedResources } from 'jmt-fetch-component-resources'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../utils/custom-hooks/useTranslation'
import { Modal } from '../base/layouts/Modal'
import { hideProfileModalAction } from './state/profile-modal-actions'

const MyProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  min-height: 50px;
  flex-shrink: 0;
`

const Content = styled.div`
  overflow-y: auto;
  flex: 1;
  max-height: 500px;

  @media (max-width: 992px) {
    max-height: 350px;
  }
`

export const ProfileModal = () => {
  const {
    showModal,
    addressType,
  } = useShallowEqualSelector((storeState) => storeState.profileModalReducer)

  const dispatch = useDispatch()

  const theme = useTheme()
  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const {
    oauthUrl,
    apiUrl,
    instance,
    language,
    identityUrl,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  useEffect(() => {
    fetchComponentTemplatedResources({
      manageSubUrl: 'vmx.my-profile/vmx.component.my-profile',
      componentType: 'vue',
      version: '1.4.1',
    })
  }, [])

  useEffect(() => {
    const catchEventAfterSave = () => {
      dispatch(hideProfileModalAction())
    }
    window.addEventListener('my-profile.eventAfterSave', catchEventAfterSave)
    return () => {
      window.removeEventListener('my-profile.eventAfterSave', catchEventAfterSave)
    }
  }, [])

  const { t } = useTranslation()

  if (!showModal) {
    return null
  }

  return (
    <Modal
      isVisible
      onClose={() => dispatch(hideProfileModalAction())}
    >
      <MyProfileContainer>
        <Header>
          <h3>
            {t('Profile_Modal-User_Complete-Title')}
          </h3>
        </Header>
        <Content>
          <div
            id="my-profile"
            token={token}
            identity_url={identityUrl}
            oauth_url={oauthUrl}
            client_id=""
            address_type={addressType}
            api_url={apiUrl}
            instance={instance}
            language={language}
            event_short="era"
            context="society"
            hide_title="true"
            color={theme.primaryColor}
            event_after_save="true"
            save_button_text={t('Profile_Modal-User_Complete-Save_Button')}
          >
            <Loader />
          </div>
        </Content>
      </MyProfileContainer>
    </Modal>
  )
}
