import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Text, FlexBox } from 'jmt-styled-components'

import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

const TabContainer = styled.span`
  margin: 0px 15px 15px 0px;
  cursor: pointer;

  ${(p) => p.template}
`

export const CongressTabs = ({
  setEventFilter,
  eventFilter,
  eventGroups,
}) => {
  const { props: templateProps } = useTemplate('InlayTabs')

  const tabContainerTemplate = css`
    color: ${templateProps.tabColor};
    border-bottom: 3px solid ${(p) => (p.isSelected ? templateProps.tabColor : 'transparent')};
  `
  return (
    <FlexBox>
      {
        eventGroups.map((group) => (
          <TabContainer
            template={tabContainerTemplate}
            key={group}
            isSelected={group === eventFilter}
            onClick={() => setEventFilter(group)}
          >
            <Text isPrimary isBold fontSize="1.2rem">
              {group.toUpperCase()}
            </Text>
          </TabContainer>
        ))
    }
    </FlexBox>
  )
}

CongressTabs.propTypes = {
  setEventFilter: PropTypes.func.isRequired,
  eventFilter: PropTypes.string.isRequired,
  eventGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
}
