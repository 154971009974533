import PropTypes from 'prop-types'
import { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'

import { useTranslation } from '../../utils/custom-hooks/useTranslation'
import { Button } from '../base/buttons/Button'
import { acceptTermsAndConditions } from './state/conditions-checker-actions'

const TermsConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  min-height: 50px;
  flex-shrink: 0;
`

const Content = styled.div`
  overflow-y: auto;
  flex: 1;
  max-height: 500px;

  @media (max-width: 992px) {
    max-height: 350px;
  }
`

const Footer = styled.div`
  min-height: 50px;
  flex-shrink: 0;
`

const TermsContainer = styled.div`
  padding: 10px 5px;
  border: 1px solid black;
  margin: 10px 0;
`

const Section = styled.section`
  margin-top: 10px;
`

const Label = styled.label`
  padding-left: 10px;
`

const InjectedHtml = styled.div`
  * {
    all: revert;
  }
`

export const TermsConditionsSlide = ({
  onContinue,
  data,
}) => {
  const [termsAccepted, setTermsAccepted] = useState(data.map(() => false))
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <TermsConditionsContainer>
      <Header>
        <h3>
          {t('Conditions_Checker-Terms_And_Conditions-Title')}
        </h3>
      </Header>
      <Content>
        {
          data.map((terms, idx) => (
            <TermsContainer key={terms.Name}>
              <h4>
                {idx + 1}
                .
                {' '}
                {terms.Name}
              </h4>
              <InjectedHtml dangerouslySetInnerHTML={{ __html: terms.Content }} />
            </TermsContainer>
          ))
        }
      </Content>
      <Footer>
        {
          data.map((terms, idx) => (
            <Fragment key={terms.Name}>
              {
                terms.IsPrivacyPolicy
                  ? null
                  : (
                    <Section>
                      <input
                        type="checkbox"
                        checked={termsAccepted[idx]}
                        onChange={(e) => {
                          const nextTerms = [...termsAccepted]
                          nextTerms[idx] = e.target.checked
                          setTermsAccepted(nextTerms)
                        }}
                      />
                      <Label
                        dangerouslySetInnerHTML={{
                          __html: `${t('Conditions_Checker-Terms_And_Conditions-Checkbox_Label')}: ${terms.Name}`,
                        }}
                      />
                    </Section>
                  )
              }
            </Fragment>
          ))
        }
        <Button
          disabled={termsAccepted.some((item) => !item)}
          onClick={() => {
            dispatch(acceptTermsAndConditions(
              data.map((item) => item.Id), // ids array
              onContinue,
            ))
          }}
        >
          {t('Conditions_Checker-Terms_And_Conditions-Save_Continue_Button')}
        </Button>
      </Footer>
    </TermsConditionsContainer>
  )
}

TermsConditionsSlide.propTypes = {
  onContinue: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Content: PropTypes.string.isRequired,
      Id: PropTypes.number.isRequired,
      IsPrivacyPolicy: PropTypes.bool,
      Name: PropTypes.string.isRequired,
      Type: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
