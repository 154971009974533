import styled from '@emotion/styled'
import { Section as JmtSection } from 'jmt-styled-components'
import PropTypes from 'prop-types'
import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

const StyledSection = styled(JmtSection)`
  ${(p) => (p.backgroundColor ? `background-color: ${p.backgroundColor};` : '')}
`

export const Section = ({ children }) => {
  const { props: templateProps } = useTemplate('Section')

  return (
    <StyledSection backgroundColor={templateProps.backgroundColor}>
      {children}
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}
