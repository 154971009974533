import { useEffect } from 'react'
import { PageTitle } from 'jmt-styled-components'
import { useTheme } from '@emotion/react'
import PropTypes from 'prop-types'
import { fetchComponentExternalResources } from 'jmt-fetch-component-resources'

import styled from '@emotion/styled'
import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { useShallowEqualSelector } from '../../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'

const CustomQueriesContainer = styled.div`
  & > div > div > div {
    padding: 20px;
  }
`

export const AreaOfInterest = ({ title }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    apiUrl,
    language,
    short,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)
  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)

  useEffect(() => {
    fetchComponentExternalResources({
      manageSubUrl: 'm-anage.com.custom-queries/m-anage.com.component.custom-queries',
      version: 'v4.0.15',
      files: [
        {
          tag: 'script',
          name: 'main',
          ending: 'js',
        },
        {
          tag: 'link',
          name: 'main',
          ending: 'css',
        },
      ],
    })
  }, [])
  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>

      <CustomQueriesContainer>
        <div
          id="m-anage.com.component.custom-queries"
          apiurl={apiUrl}
          short={short}
          context="Profile"
          language={language}
          theme={JSON.stringify(theme)}
          querytype="AreaInterest"
          paymentcontext="Company"
          accesstoken={token}
        />
      </CustomQueriesContainer>
    </PageContainer>
  )
}

AreaOfInterest.propTypes = {
  title: PropTypes.string.isRequired,
}
