import {
  SET_PROFILE_MODAL,
  RESET_PROFILE_MODAL,
} from './profile-modal-types'

export const showProfileModalAction = ({ addressType }) => (dispatch) => {
  dispatch({
    type: SET_PROFILE_MODAL,
    payload: {
      showModal: true,
      addressType,
    },
  })
}

export const hideProfileModalAction = () => (dispatch) => {
  dispatch({
    type: RESET_PROFILE_MODAL,
  })
}
