import {
  lazy,
} from 'react'
import { CustomSuspense } from '../../components/CustomSuspense'

const LazyDocuments = lazy(() => import('../Documents'))

export const Documents = (props) => (
  <CustomSuspense>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LazyDocuments {...props} />
  </CustomSuspense>
)
