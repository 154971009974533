import { SET_MODULE_CONFIG } from './module-config-types'

const initialState = {
  associationName: '',
  associationId: '',
  isI18nLoaded: false,
  language: null,
  oauthUrl: null,
  apiUrl: null,
  identityUrl: null,
  short: null,
  context: null,
  instance: null,
  associationConfig: {},
}

export const moduleConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODULE_CONFIG:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
