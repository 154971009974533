import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const StyledText = styled.span`
  display: contents;
  ${(p) => p.fontSize && css`font-size: ${p.fontSize};`}
  ${(p) => p.isBold && css`font-weight: bold;`}
  ${(p) => p.isPrimary && css`color: ${p.theme.primaryColor};`}
  ${(p) => p.color && css`color: ${p.color};`}
`

export const Text = ({
  fontSize,
  isBold,
  isPrimary,
  color,
  children,
}) => {
  if (!children) {
    return null
  }
  if (Array.isArray(children)) {
    const joinedChildren = children.join('')
    return (
      <StyledText
        fontSize={fontSize}
        color={color}
        isBold={isBold}
        isPrimary={isPrimary}
        dangerouslySetInnerHTML={{
          __html: typeof children === 'function' ? children.props.children : joinedChildren,
        }}
      />
    )
  }
  return (
    <StyledText
      fontSize={fontSize}
      color={color}
      isBold={isBold}
      isPrimary={isPrimary}
      dangerouslySetInnerHTML={{
        __html: ['function', 'object'].includes(typeof children) ? children.props.children : children,
      }}
    />
  )
}

Text.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string,
  isBold: PropTypes.bool,
  isPrimary: PropTypes.bool,
  children: PropTypes.node,
}

Text.defaultProps = {
  fontSize: '',
  color: '',
  isBold: false,
  isPrimary: false,
  children: '',
}
