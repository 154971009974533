import { AuthRoute } from './routing/AuthRoute'

export const DynamicRoutesWithAccessCheck = ({
  componentsSelection,
  routes,
  leadingPath = '',
}) => routes.map((route) => {
  const DynamicRoute = componentsSelection[route.componentName]
  const path = `${leadingPath}${route.path}`
  return (
    <AuthRoute
      path={path}
      key={route.path}
      exact={!route.subRoutes}
      routerConfig={route.props}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DynamicRoute {...route.props} />
    </AuthRoute>
  )
})
