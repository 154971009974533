import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import missingImage from '../assets/images/MyEASD_Default User_Profile Image.png'

import { Text } from './base/texts/Text'
import { useTranslation } from '../utils/custom-hooks/useTranslation'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'

const Container = styled.div`
  display: flex;
  margin-right: 25px;

  @media (max-width: 992px) {
    padding: 0;
  }
`

const ImageContainer = styled.div`
  padding: 15px 10px 15px 0;
`

const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid ${(p) => p.theme.primaryColor};
`

const ContentContainer = styled.div`
  padding: 10px 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

const ProfileTitle = styled.div`
  padding: 3px 0;
  font-weight: bold;
  font-size: 20px;
`

const ButtonContainer = styled.div`
  padding-top: 5px;
`

const EditButton = styled.button`
  background: none;
  border: none;
  color: ${(p) => p.theme.primaryColor};
  padding: 0;
`

const IconContainer = styled.span`
  padding-left: 10px;
`

export const UserProfile = () => {
  const {
    profile_pic_url: ProfilePicturePath,
    academic_title: title,
    firstname,
    surname,
    contact = {},
    email,
    membership = {},
  } = useShallowEqualSelector((state) => ({
    ...state.appInfoReducer.summery.User,
    contact: {
      street: state.appInfoReducer.summery.User.street,
      country: state.appInfoReducer.summery.User.country,
      city: state.appInfoReducer.summery.User.city,
    },
    membership: {
      number: state.appInfoReducer.membership.Number,
      status: state.appInfoReducer.membership.Status,
    },
  }))
  const { t } = useTranslation()
  const userDetails = false
  return (
    <Container>
      <ImageContainer>
        {
        ProfilePicturePath
          ? (
            <ProfileImage
              src={ProfilePicturePath}
              height="100px"
              width="100px"
              alt=""
            />
          ) : (
            <ProfileImage
              src={missingImage}
              height="100px"
              width="100px"
              alt=""
            />
          )
      }
      </ImageContainer>
      <ContentContainer>
        <ProfileTitle>
          {title}
          {' '}
          {firstname}
          {' '}
          {surname}
        </ProfileTitle>
        {
          userDetails
            ? (
              <div>
                <div>
                  <span>
                    {contact.street}
                  </span>
                </div>
                <div>
                  <span>
                    {contact.city}
                    {' '}
                    {contact.country}
                  </span>
                </div>
              </div>
            ) : null
        }
        <div>
          <span>{email}</span>
        </div>
        {
          membership.number
            ? (
              <div>
                <span dangerouslySetInnerHTML={{ __html: t('Profile_Overview-Membership_Number') }} />
                <span>
                  :
                  {' '}
                  {membership.number}
                </span>
              </div>
            ) : null
        }

        <ButtonContainer>
          <div>
            <Link to="/myprofile">
              <EditButton>
                <Text isBold>
                  {t('Profile_Overview-Edit_Button')}
                </Text>
                <IconContainer />
              </EditButton>
            </Link>
          </div>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}
