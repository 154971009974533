import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchComponentExternalResources } from 'jmt-fetch-component-resources'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'

export const DoiNotification = () => {
  const history = useHistory()
  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const {
    apiUrl,
    short,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  useEffect(() => {
    fetchComponentExternalResources({
      manageSubUrl: 'm-anage.com.doi-notification/m-anage.com.component.doi-notification',
      version: '1.0.1',
      files: [
        {
          tag: 'script',
          name: 'main',
          ending: 'js',
        },
      ],
    })
  }, [])

  useEffect(() => {
    const move = () => {
      history.push('/doi/form')
    }
    window.addEventListener('edit-doi', move)

    return () => {
      window.removeEventListener('edit-doi', move)
    }
  }, [])

  if (!token) {
    return null
  }

  return (
    <div
      id="m-anage.com.component.doi-notification"
      apiurl={apiUrl}
      accesstoken={token}
      societyshort={short}
      language="en-US"
      context="Society"
    />
  )
}
