import { ManageFetch } from '../api/manage-fetch'

export const checkPermission = ({ modulePermissions, onPermitted, onDenied }) => {
  ManageFetch.request`${(p) => p.apiUrl}/v2/core/CheckPermissionsForModule`({
    method: 'POST',
    body: {
      ...modulePermissions,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      const { data } = res
      const result = data[0]
      if (result.data && modulePermissions.moduleName === result.name) { // means no actions needed
        onPermitted()
      } else { // user needs to fill up some details
        onDenied()
      }
    })
    .catch((err) => {
      console.log('checkPermissions fetch failed', err)
      onDenied()
    })
}
