import { isValidElement, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { Icon, Modal } from 'jmt-styled-components'

import { Button } from '../base/buttons/Button'
import { Card } from '../base/layouts/Card'
import { Text } from '../base/texts/Text'
import { checkPermissions } from '../conditions-checker/state/conditions-checker-actions'
import { MasterAnchor } from '../MasterAnchor'
import { useTemplate } from '../../utils/custom-hooks/use-getters/useTemplate'
import { setNavigatedToExternalAction } from '../../state/router/router-actions'
import { useTranslation } from '../../utils/custom-hooks/useTranslation'

const CardBody = styled.div`
  padding: 25px;
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex: 1;
  flex-direction: column;

  & > h3 {
    margin-bottom: 1rem;
  }
  & > p {
    padding-bottom: 1rem;
  }
  & > a, & > div, & > span {
    margin-top: auto;
  }
`

const ImageContainer = styled.div`
  text-align: center;
`

const CardImage = styled.img`
  object-fit: contain;
  ${(p) => {
    if (p.isOnlyImage) {
      return css`
        width: 100%;
      `
    }
    return css`
      max-width: 100%;
      min-height: 122px;
      max-height: 122px;
    `
  }}
`

const ModalWrapper = styled.div`
  & [class$='JMT-General-ModalContent'] {
    width: initial;
    max-width: 80vw;
  }
`

const ModalContent = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
`

export const LinkBox = ({
  size,
  data,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isOnlyImage = !(
    data.title
    || data.description
    || data.buttonTitle
    || data.buttonIcon
  ) || size === 'full'
  const [showLoader, setShowLoader] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const allowModal = useMemo(() => (data.redirectModal != null), [])

  const { props: templateProps } = useTemplate('LinkBox')

  const buttonTemplate = css`
    background-color: ${templateProps.buttonBackgroundColor};
    color: ${templateProps.buttonColor};
  `

  return (
    <>
      <Card backgroundColor={templateProps.backgroundColor} feelRow>
        {
          data.image && (
            <ImageContainer>
              {
                data.imageLink
                  ? (
                    <a href={data.imageLink.url} target={data.imageLink.target}>
                      <CardImage isOnlyImage={isOnlyImage} src={data.image} />
                    </a>
                  )
                  : (
                    <CardImage isOnlyImage={isOnlyImage} src={data.image} />
                  )
              }
            </ImageContainer>
          )
        }
        {
          !isOnlyImage && (
            <CardBody>
              {
                isValidElement(data.title)
                  ? (
                    data.title
                  )
                  : (
                    <h3>
                      <Text fontSize="1.2rem" isBold color={templateProps.titleColor}>
                        {data.title}
                      </Text>
                    </h3>
                  )
              }
              {
                data.description && (
                  <p dangerouslySetInnerHTML={{ __html: data.description }} />
                )
              }
              {
                (() => {
                  if (!data.link.url) {
                    return null
                  }
                  return (
                    <MasterAnchor to={data.link.url} target={data.link.target}>
                      {
                        ({ onGo, isRelative }) => (
                          <Button
                            padding="10px 15px"
                            showLoader={showLoader}
                            template={buttonTemplate}
                            onClick={(e) => {
                              if (allowModal) {
                                e.preventDefault()
                                setIsModalVisible(true)
                              }
                              if (data.modulePermissions) {
                                e.preventDefault()
                                if (isRelative) {
                                  setShowLoader(true)
                                  dispatch(
                                    checkPermissions(
                                      data.modulePermissions,
                                      () => {
                                        setShowLoader(false)
                                        onGo()
                                      },
                                      () => {
                                        setShowLoader(false)
                                      },
                                    ),
                                  )
                                } else {
                                  const encodedAction = btoa(JSON.stringify({
                                    modulePermissions: data.modulePermissions,
                                    target: data.link.url,
                                  }))
                                  // required to fix an issue when firefox tab becomes inactive after navigating to external page
                                  dispatch(setNavigatedToExternalAction(true))
                                  window.open(`/permissions-checker?action=${encodedAction}`)
                                }
                              }
                            }}
                          >
                            <Text isBold>
                              {data.buttonTitle}
                            </Text>
                            {
                            data.buttonIcon && (
                              <Icon
                                icon={data.buttonIcon}
                                color="#ffffff"
                              />
                            )
                        }
                          </Button>
                        )
                      }
                    </MasterAnchor>
                  )
                })()
              }
            </CardBody>
          )
        }
      </Card>
      {
        allowModal && isModalVisible
          ? (
            <ModalWrapper>

              <Modal
                isVisible={isModalVisible}
                onClose={() => {
                  setIsModalVisible(false)
                }}
                headerContent={data.redirectModal.Title}
              >
                {
                  data.redirectModal.Description && (
                    <ModalContent
                      dangerouslySetInnerHTML={{ __html: data.redirectModal.Description }}
                    />
                  )
                }
                <div>
                  <MasterAnchor to={data.link.url} target={data.link.target}>
                    {
                      ({ onGo, isRelative }) => (
                        <Button
                          padding="10px 15px"
                          showLoader={showLoader}
                          template={buttonTemplate}
                          onClick={(e) => {
                            if (data.modulePermissions) {
                              e.preventDefault()
                              if (isRelative) {
                                setShowLoader(true)
                                dispatch(
                                  checkPermissions(
                                    data.modulePermissions,
                                    () => {
                                      setShowLoader(false)
                                      onGo()
                                    },
                                    () => {
                                      setShowLoader(false)
                                    },
                                  ),
                                )
                              } else {
                                const encodedAction = btoa(JSON.stringify({
                                  modulePermissions: data.modulePermissions,
                                  target: data.link.url,
                                }))
                                // required to fix an issue when firefox tab becomes inactive after navigating to external page
                                dispatch(setNavigatedToExternalAction(true))
                                window.open(`/permissions-checker?action=${encodedAction}`)
                              }
                            }
                          }}
                        >
                          <Text isBold>
                            {t('LinkBox-External_Website_Modal-Ok_Button')}
                          </Text>
                        </Button>
                      )
                        }
                  </MasterAnchor>
                </div>
              </Modal>
            </ModalWrapper>
          )
          : null
      }
    </>
  )
}

LinkBox.propTypes = {
  size: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    description: PropTypes.string,
    buttonTitle: PropTypes.string,
    buttonIcon: PropTypes.string,
    redirectModal: PropTypes.shape({
      Title: PropTypes.string,
      Description: PropTypes.string,
    }),
    link: PropTypes.shape({
      url: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }),
    image: PropTypes.string,
    imageLink: PropTypes.shape({
      url: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }),
    modulePermissions: PropTypes.shape({
      TermsConditionsId: PropTypes.number,
      EventModuleId: PropTypes.number,
      Context: PropTypes.string,
      ShortName: PropTypes.string,
    }),
  }).isRequired,
}

LinkBox.defaultProps = {
  size: null,
}
