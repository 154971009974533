import { ManageFetch } from '../../../api/manage-fetch'
import {
  SET_CONDITION_CHECKER,
  RESET_CONDITION_CHECKER,
} from './conditions-checker-types'

export const checkPermissions = (modulePermissions, onSuccess, onFail) => (dispatch) => {
  dispatch({ type: SET_CONDITION_CHECKER, payload: { isChecking: true } })
  ManageFetch.request`${(p) => p.apiUrl}/api/core/CheckPermissionsForModules`({
    method: 'POST',
    body: {
      eventModuleId: modulePermissions.EventModuleId,
      termsConditionsId: modulePermissions.TermsConditionsId,
      context: modulePermissions.Context,
      shortName: modulePermissions.ShortName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      const { data } = res
      if (!data.length) { // means no actions needed
        onSuccess()
      } else { // user needs to fill up some details
        console.log({ data })
        dispatch({
          type: SET_CONDITION_CHECKER,
          payload: {
            isChecking: false,
            showModal: true,
            requiredActions: [
              ...data.map((item) => {
                if (item.Name === 'UserComplete') {
                  return item
                }
                return { ...item }
              }),
            ],
            onSuccess,
            onFail,
          },
        })
      }
    })
    .catch((err) => {
      console.log('checkPermissions fetch failed', err)
      onFail()
    })
}

export const acceptTermsAndConditions = (termsConditionsIds, onSuccess) => () => {
  const pTermAcceptReqs = termsConditionsIds.map((pTermIdReq) => (
    ManageFetch.request`${(p) => p.apiUrl}/api/core/TermsAndConditionAccept`({
      query: {
        pTermsId: pTermIdReq,
      },
      method: 'POST',
    })
      .then((res) => res.data)
      .catch((err) => {
        console.log('acceptTermsAndConditions fetch failed', err)
      })
  ))

  Promise.all(pTermAcceptReqs)
    .then((allRes) => {
      if (allRes.every((res) => res)) {
        onSuccess()
      } else {
        console.log('not all requests went through')
      }
    })
    .catch((err) => {
      console.log({ err })
    })
}

export const hideConditionsModal = () => (dispatch) => {
  dispatch({
    type: RESET_CONDITION_CHECKER,
  })
}

export const explicitPermissionsCheckAction = ({
  modulePermissions,
  onPermissionsMissing,
  onPermissionsGiven,
  onFail,
}) => (dispatch) => {
  ManageFetch.request`${(p) => p.apiUrl}/api/core/CheckPermissionsForModules`({
    method: 'POST',
    body: {
      eventModuleId: modulePermissions.EventModuleId,
      termsConditionsId: modulePermissions.TermsConditionsId,
      context: modulePermissions.Context,
      shortName: modulePermissions.ShortName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      const { data } = res
      if (data.length === 0) {
        onPermissionsGiven()
        return
      }
      if (data.length > 0) {
        dispatch({
          type: SET_CONDITION_CHECKER,
          payload: {
            requiredActions: [
              ...data.map((item) => {
                if (item.Name === 'UserComplete') {
                  return item
                }
                return { ...item }
              }),
            ],
            onSuccess: onPermissionsGiven,
            onFail,
          },
        })
        onPermissionsMissing()
      }
    })
    .catch((err) => {
      console.log('checkPermissions fetch failed', err)
    })
}
