import { useState } from 'react'
import PropTypes from 'prop-types'

import { MyProfileSlide } from './MyProfileSlide'
import { TermsConditionsSlide } from './TermsConditionsSlide'
import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'

const slides = {
  UserComplete: MyProfileSlide,
  TermsAndConditions: TermsConditionsSlide,
}

export const ConditionsSlider = ({
  onFinish,
}) => {
  const [slideState, setSlideState] = useState(0)

  const actionsSlides = useShallowEqualSelector((storeState) => {
    const { requiredActions } = storeState.conditionsCheckerReducer
    return [...requiredActions.map((action) => ({ ...action, component: slides[action.Name] }))]
  })

  const CurrentSlide = actionsSlides[slideState].component

  return (
    <CurrentSlide
      data={actionsSlides[slideState].Data}
      onContinue={() => {
        if (slideState + 1 < actionsSlides.length) {
          setSlideState(slideState + 1)
        } else {
          onFinish()
        }
      }}
    />
  )
}

ConditionsSlider.propTypes = {
  onFinish: PropTypes.func.isRequired,
}
