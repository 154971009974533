import {
  BrowserRouter as Router,
} from 'react-router-dom'

import { lazy, useEffect } from 'react'
import { ConditionsChecker } from '../components/conditions-checker/ConditionsChecker'
import { ProfileModal } from '../components/profile-modal/ProfileModal'
import { RouterWrappers } from './router-setup/RouterWrappers'
import { useFlags } from '../utils/custom-hooks/use-getters/useFlags'

const FreshChat = lazy(() => import('../components/FreshChat'))

export const RouterRoot = () => {
  const { showMaintenancePage, enableFreshChat } = useFlags()

  useEffect(() => {
    const { pathname } = window.location

    if (
      showMaintenancePage === true
      && pathname !== '/maintenance'
    ) {
      window.location.href = '/maintenance'
    }
  }, [])

  return (
    <Router>
      <RouterWrappers />
      <ConditionsChecker />
      <ProfileModal />
      {enableFreshChat && <FreshChat />}
    </Router>
  )
}
