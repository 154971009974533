import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import { useState } from 'react'

import { Icon } from 'jmt-styled-components'
import { Link } from 'react-router-dom'
import { LinksList } from './LinksList'
import { Navbar } from './routing/Navbar'
import { LoggedInUserThumbnail } from './routing/LoggedInUserThumbnail'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'
import { useTemplate } from '../utils/custom-hooks/use-getters/useTemplate'

const HeaderContainer = styled.div`
  background-color: ${(p) => p.backgroundColor || '#FFFFFF'};
  width: 100%;
  ${(p) => (p.fontFamily ? `font-family: '${p.fontFamily}';` : '')}

`

const MetaLinksContainer = styled.div`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 115px;
  height: 20px;
  justify-content: flex-end;
  border-bottom: 1px solid ${(p) => p.borderBottomColor || '#E3E3E3'};

  @media (max-width: 992px) {
    display: none;
  }

  & a {
    ${(p) => p.template}
  }
`

const HeaderBlock = styled.div`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 15px 115px 15px 100px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.backgroundColor || '#FFFFFF'};
  width: 100%;
  height: fit-content;

  @media (max-width: 992px) {
    z-index: 101;
    padding: 15px 25px;
    position: fixed;
    justify-content: flex-start;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  }
`

const MobileNavContainer = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
    padding-right: 10px;
    ${(p) => (p.isVisible || css`visibility: hidden;`)}
  }
`

const OnlyLGVisible = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`

const CompanyLogo = styled.img`
  max-width: 300px;
`

export const Header = () => {
  const { header } = useShallowEqualSelector((store) => (
    store.moduleConfigReducer.associationConfig
  ))

  const theme = useTheme()

  const metaLinks = header.associationHeaderLinks
    ? header.associationHeaderLinks.map((link) => ({
      url: link.url,
      title: link.text,
    }))
    : []

  const { token } = useShallowEqualSelector((storeState) => storeState.userInfoReducer)

  const { props: templateProps } = useTemplate('Header')
  const { props: templatePropsMeta } = useTemplate('MetaLinks')
  const [toggleNavState, setToggleNav] = useState(false)

  const metaLinksTemplate = css`
    color: ${templatePropsMeta.desktopMetaLinkColor};
  `

  return (
    <HeaderContainer
      backgroundColor={templateProps.backgroundColor}
      fontFamily={templateProps.fontFamily}
    >
      <MetaLinksContainer
        borderBottomColor={templatePropsMeta.desktopBorderBottomColor}
        template={metaLinksTemplate}
      >
        <LinksList
          links={metaLinks}
        />
      </MetaLinksContainer>
      <HeaderBlock backgroundColor={templateProps.backgroundColor}>
        <MobileNavContainer isVisible={token}>
          <Icon
            icon="fa-bars"
            size="lg"
            color={theme.primaryColor}
            onClick={(e) => {
              e.stopPropagation()
              setToggleNav(!toggleNavState)
            }}
          />
        </MobileNavContainer>
        <div>
          {
            token
              ? (
                <Link to="/" disabled>
                  <CompanyLogo height="50px" src={header.logoUrl} alt="" />
                </Link>
              ) : (
                <a href={window.location.origin}>
                  <CompanyLogo height="50px" src={header.logoUrl} alt="" />
                </a>
              )
          }
        </div>
        <OnlyLGVisible>
          <LoggedInUserThumbnail />
        </OnlyLGVisible>
        <MobileNavContainer />
      </HeaderBlock>
      <Navbar
        token={token}
        mobileNavVisible={toggleNavState}
        metaLinks={metaLinks}
        onClick={() => setToggleNav(false)}
      />
    </HeaderContainer>
  )
}
