import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

const CardWrapper = styled.div`
  ${(p) => p.feelRow && css`height: 100%;`}
  ${(p) => p.wrapperAdditionalStyles || ''}
`

const StyledCard = styled.div`
  background-color: ${(p) => p.backgroundColor || '#ffffff'};
  display: flex;
  flex-direction: ${(p) => p.flexDir || 'column'};
  height: 100%;
  ${(p) => p.padding && css`padding: ${p.padding};`}
`

export const Card = ({
  wrapperAdditionalStyles,
  backgroundColor,
  flexDir,
  children,
  feelRow,
  padding,
}) => {
  const { props: templateProps } = useTemplate('Card')
  return (
    <CardWrapper
      wrapperAdditionalStyles={wrapperAdditionalStyles}
      feelRow={feelRow}
    >
      <StyledCard
        backgroundColor={backgroundColor || templateProps.backgroundColor}
        flexDir={flexDir}
        padding={padding}
      >
        {children}
      </StyledCard>
    </CardWrapper>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperAdditionalStyles: PropTypes.string,
  flexDir: PropTypes.string,
  backgroundColor: PropTypes.string,
  feelRow: PropTypes.bool,
  padding: PropTypes.string,
}

Card.defaultProps = {
  flexDir: '',
  feelRow: false,
  backgroundColor: null,
  padding: '',
  wrapperAdditionalStyles: '',
}
