import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const FlexBox = styled.div`
  display: flex;
  ${(p) => p.dir && css`flex-direction: ${p.dir};`}
  ${(p) => p.jstCont && css`justify-content: ${p.jstCont};`}
  ${(p) => p.algnItm && css`align-items: ${p.algnItm};`}
  
  ${(p) => p.additional}
`

FlexBox.propTypes = {
  dir: PropTypes.string,
  jstCont: PropTypes.string,
  algnItm: PropTypes.string,
}

FlexBox.defaultProps = {
  dir: '',
  jstCont: '',
  algnItm: '',
}
