import {
  SET_PROFILE_MODAL,
  RESET_PROFILE_MODAL,
} from './profile-modal-types'

const initialState = {
  addressType: null,
  showModal: false,
}

export const profileModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_MODAL:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_PROFILE_MODAL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
