import {
  useEffect,
} from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  fetchTranslationsAction,
} from '../../state/module-config/module-config-actions'
import { Congresses } from '../congresses/Congresses'
import { LandingPage } from '../LandingPage'
import { Membership } from '../membership/Membership'
import { MyProfile } from '../my-profile/MyProfile'
import { Auth } from '../Auth'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { ErrorRoute } from '../ErrorRoute'
import { MaintenanceRoute } from '../MaintenanceRoute'
import { NotFound } from '../NotFound'
import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'

import { Documents } from '../lazy-routes/Documents'
import { Jobs } from '../lazy-routes/Jobs'
import { Doi } from '../lazy-routes/Doi'
import { DoiNotification } from './DoiNotification'
import { PermissionsChecker } from '../permissions-checker/PermissionsChecker'
import { AuthRoute } from '../../components/routing/AuthRoute'
import { DynamicRoutesWithAccessCheck } from '../../components/DynamicRoutesWithAccessCheck'

const dynamicComponents = {
  Congresses,
  Documents,
  Jobs,
  LandingPage,
  Membership,
  Doi,
  MyProfile,
}

export const AfterCatchersAreSet = () => {
  const dispatch = useDispatch()

  const {
    isI18nLoaded,
    associationConfig,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  const { pages } = associationConfig
  const doiConfig = pages.find((page) => page.componentName === 'Doi')

  useEffect(() => {
    dispatch(fetchTranslationsAction())
  }, [])

  if (!isI18nLoaded) {
    return (
      <>
        <Route path="/" />
        <Route path="/error" exact>
          <ErrorRoute />
        </Route>
        <Route path="/maintenance" exact>
          <MaintenanceRoute />
        </Route>
      </>
    )
  }

  const dynamicWithAccess = DynamicRoutesWithAccessCheck({
    routes: pages,
    componentsSelection: dynamicComponents,
  })

  return (
    <>
      <Header />
      {
        (() => {
          const notificationNeeded = doiConfig?.subRoutes?.find((route) => route.componentName === 'DoiForm')
          if (notificationNeeded) {
            return <DoiNotification />
          }
          return null
        })()
      }
      <Switch>
        {dynamicWithAccess}
        <Route
          path="/auth"
          key="/auth"
        >
          <Auth />
        </Route>
        <Route path="/error" exact>
          <ErrorRoute />
        </Route>
        <Route path="/maintenance" exact>
          <MaintenanceRoute />
        </Route>
        <AuthRoute
          path="/permissions-checker"
          key="/permissions-checker"
          exact
          routerConfig={{ title: '' }}
        >
          <PermissionsChecker />
        </AuthRoute>
        ,
        <Route path="/">
          <NotFound />
        </Route>
      </Switch>
      <Footer />
    </>
  )
}
