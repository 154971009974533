import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom'
import { PageContainer, Loader } from 'jmt-styled-components'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { MembersDirectory } from './sub-routes/MembersDirectory'
import { MembershipApplication } from './sub-routes/MembershipApplication'
import { MembershipRoot } from './sub-routes/MembershipRoot'
import { NotFound } from '../NotFound'
import { GeneralAssembly } from './sub-routes/GeneralAssembly'
import { fetchMembershipInfo } from '../../state/app-info/app-info-actions'
import { DynamicRoutesWithAccessCheck } from '../../components/DynamicRoutesWithAccessCheck'

const dynamicComponents = {
  MembersDirectory,
  MembershipApplication,
  GeneralAssembly,
}

export const Membership = () => {
  const { path } = useRouteMatch()
  const {
    fetching,
  } = useShallowEqualSelector((state) => state.appInfoReducer)
  const {
    associationConfig,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMembershipInfo())
  }, [])

  const { pages } = associationConfig
  const membershipConfig = pages.find((page) => page.componentName === 'Membership')

  if (
    !fetching.membership
    || fetching.membership === 'fetching'
  ) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    )
  }

  const dynamicRoutesWithAccess = DynamicRoutesWithAccessCheck({
    routes: membershipConfig.subRoutes,
    componentsSelection: dynamicComponents,
    leadingPath: path,
  })

  return (
    <Switch>
      <Route path={`${path}`} exact>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MembershipRoot {...membershipConfig.props} />
      </Route>
      {dynamicRoutesWithAccess}
      <Route path={`${path}`}>
        <NotFound />
      </Route>
    </Switch>
  )
}
