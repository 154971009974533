import {
  AsyncInput, CheckboxInput, Loader, PageContainer, PageTitle,
} from 'jmt-styled-components'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { useDispatch } from 'react-redux'
import { FlexBox } from '../../../components/base/layouts/FlexBox'
import { Card } from '../../../components/base/layouts/Card'
import { RowItem } from '../../membership/components/RowItem'
import { ManageFetch } from '../../../api/manage-fetch'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'
import { FreeUiRssBox } from '../../../components/FreeUiRssBox'
import { setUserFlags } from '../../../state/user-info/user-info-actions'

const CardBody = styled.div`
  padding: 20px 25px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > h3 {
    color: ${(p) => p.theme.primaryColor};
    margin-bottom: 1rem;
  }
  & > p {
    padding-bottom: 1rem;
  }
  & > a {
    margin-top: auto;
  }
`

export const NewslettersRapidMail = ({ title }) => {
  const { t } = useTranslation()
  const [newsletters, setNewsletters] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUserFlags({ oneTimeLoginRedirect: { newslettersViewed: true } }))
  }, [])

  useEffect(() => {
    ManageFetch.request`${(p) => p.apiUrl}/v2/Rapidmail/Recipientlists`({
      query: {
        societyShort: (p) => p.short,
      },
    })
      .then((res) => {
        setNewsletters(res.data)
      }).catch((err) => {
        console.log({ err })
      })
  }, [])

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>

      <FreeUiRssBox uiRss="NewslettersRapidMail_Page-Description-Upper" />

      {
        newsletters.length > 0
          ? (
            <Card
              wrapperAdditionalStyles={`
          margin: 5px 0;
        `}
            >
              <CardBody>
                <FlexBox
                  dir="column"
                >
                  {
              newsletters.map((newsletter, idx) => (
                <RowItem
                  key={`${newsletter.id}`}
                  label={newsletter.name}
                  value={(
                    <AsyncInput>
                      {
                      ({ loaderInit, loaderEnd, isLoading }) => (
                        <CheckboxInput
                          name={`${newsletter.name}-checkbox`}
                          checked={newsletter.recipient_id !== null}
                          disabled={isLoading}
                          onChange={() => {
                            loaderInit()
                            if (newsletter.recipient_id !== null) {
                              ManageFetch.request`${(p) => p.apiUrl}/v2/Rapidmail/recipients`({
                                query: {
                                  societyShort: (p) => p.short,
                                  recipient_id: newsletter.recipient_id,
                                },
                                method: 'DELETE',
                                body: JSON.stringify({ recipientlist_id: newsletter.id }),
                              })
                                .then((res) => {
                                  if (res.data) {
                                    const nextNewsletters = [...newsletters]
                                    nextNewsletters[idx] = {
                                      ...nextNewsletters[idx],
                                      recipient_id: null,
                                    }
                                    setNewsletters(nextNewsletters)
                                    loaderEnd()
                                  }
                                }).catch((err) => {
                                  console.log({ err })
                                })
                            } else {
                              ManageFetch.request`${(p) => p.apiUrl}/v2/Rapidmail/recipients`({
                                query: {
                                  societyShort: (p) => p.short,
                                },
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ recipientlist_id: newsletter.id }),
                              })
                                .then((res) => {
                                  if (res.data) {
                                    const nextNewsletters = [...newsletters]
                                    nextNewsletters[idx] = {
                                      ...nextNewsletters[idx],
                                      recipient_id: res.data,
                                    }
                                    setNewsletters(nextNewsletters)
                                    loaderEnd()
                                  }
                                }).catch((err) => {
                                  console.log({ err })
                                })
                            }
                          }}
                        />
                      )
                    }
                    </AsyncInput>
                )}
                />
              ))
            }
                </FlexBox>
              </CardBody>
            </Card>
          )
          : (
            <Loader />
          )
      }

      <FreeUiRssBox uiRss="NewslettersRapidMail_Page-Description-Lower" />

    </PageContainer>
  )
}

NewslettersRapidMail.propTypes = {
  title: PropTypes.string.isRequired,
}
