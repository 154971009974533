import { SET_ROUTER } from './router-types'

// required to fix an issue when firefox tab becomes inactive after navigating to external page
export const setNavigatedToExternalAction = (toggle) => (dispatch) => {
  dispatch({ type: SET_ROUTER, payload: { sentToExternal: toggle } })
}

export const setRouterAction = ({ pathname }) => (dispatch, getState) => {
  const { isGoBack } = getState().routerReducer
  if (isGoBack) {
    dispatch({ type: SET_ROUTER, payload: { isGoBack: false } })
    return
  }

  if (pathname === '/auth/login') {
    dispatch({ type: SET_ROUTER, payload: { history: [] } })
  } else {
    const { history } = getState().routerReducer
    if (pathname === history[0]) {
      return
    }
    const nextHistory = [pathname, ...history]
    dispatch({ type: SET_ROUTER, payload: { history: nextHistory } })
  }
}

export const goBackRouterAction = () => (dispatch, getState) => {
  const { history } = getState().routerReducer
  const nextHistory = history.slice(1)
  dispatch({ type: SET_ROUTER, payload: { history: nextHistory, isGoBack: true } })
}
