import { useShallowEqualSelector } from '../useShallowEqualSelector'

export const useTemplate = (componentName) => useShallowEqualSelector((state) => {
  try {
    const foundTemplate = state.moduleConfigReducer.associationConfig.design.templates
      .find((temp) => temp.name === componentName)

    if (!foundTemplate) {
      throw new Error(`${componentName} template not found`)
    }

    return foundTemplate
  } catch (e) {
    return { props: {} }
  }
})
