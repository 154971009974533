import { PageTitle } from 'jmt-styled-components'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { fetchComponentExternalResources } from 'jmt-fetch-component-resources'

import styled from '@emotion/styled'
import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { useShallowEqualSelector } from '../../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'

const PageSubTitle = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const GeneralAssembly = ({ title, showSubTitle, showComponentDescription }) => {
  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    apiUrl,
    short,
    language,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  useEffect(() => {
    fetchComponentExternalResources({
      manageSubUrl: 'm-anage.com.general-assembly/m-anage.com.component.general-assembly',
      version: 'v1.2.5',
      files: [
        {
          tag: 'script',
          name: 'main',
          ending: 'js',
        },
      ],

    })
  }, [])

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      {
        showSubTitle && (
          <PageSubTitle
            dangerouslySetInnerHTML={{ __html: t(`${title}_Page-Sub_Title`) }}
          />
        )
      }
      <div
        id="m-anage.com.component.general-assembly"
        context="Society"
        language={language}
        accesstoken={token}
        societyshort={short}
        apiurl={apiUrl}
        theme={JSON.stringify(theme)}
        showdescription={showComponentDescription && 'true'}
      >
        not yet rendered
      </div>
    </PageContainer>
  )
}

GeneralAssembly.propTypes = {
  title: PropTypes.string.isRequired,
  showSubTitle: PropTypes.bool,
  showComponentDescription: PropTypes.bool,
}

GeneralAssembly.defaultProps = {
  showSubTitle: false,
  showComponentDescription: false,
}
