import { ManageFetch } from '../../api/manage-fetch'
import { SET_USER_INFO } from './user-info-types'

export const setUserInfo = (payload) => (dispatch) => {
  ManageFetch.setInjectables({
    token: payload.token,
  })
  dispatch({ type: SET_USER_INFO, payload })
}

export const getUserFlags = () => (dispatch) => {
  ManageFetch.request`${(p) => p.apiUrl}/v2/Membership/UserParameter`({
    query: {
      societyShort: (p) => p.short,
      parameterType: 'MyAssociationProps',
    },
  })
    .then((res) => {
      const flags = res.data.props || {}
      dispatch({ type: SET_USER_INFO, payload: { flags } })
    })
}

export const setUserFlags = (payload) => (dispatch, getState) => {
  const { userInfoReducer } = getState()
  const nextFlags = {
    ...userInfoReducer.flags,
    ...payload,
  }
  ManageFetch.request`${(p) => p.apiUrl}/v2/Membership/UserParameter`({
    query: {
      societyShort: (p) => p.short,
    },
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parameter: 'MyAssociationProps',
      value: '',
      props: nextFlags,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_INFO, payload: { flags: nextFlags } })
      }
    })
}
