import { useEffect } from 'react'
import { Loader, PageTitle } from 'jmt-styled-components'
import PropTypes from 'prop-types'
import { fetchComponentExternalResources } from 'jmt-fetch-component-resources'

import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { useShallowEqualSelector } from '../../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'

export const MembersDirectory = ({ title }) => {
  const { t } = useTranslation()

  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const {
    apiUrl,
    short,
    language,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  useEffect(() => {
    fetchComponentExternalResources({
      manageSubUrl: 'm-anage.com.members-directory/m-anage.com.component.members-directory',
      version: 'v1.1.5',
      files: [
        {
          tag: 'script',
          name: 'main',
          ending: 'js',
        },
      ],

    })
  }, [])

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      <div
        id="m-anage.com.components.membership-directory"
        context="Society"
        language={language}
        accesstoken={token}
        short={short}
        apiurl={apiUrl}
      >
        <Loader />
      </div>
    </PageContainer>
  )
}

MembersDirectory.propTypes = {
  title: PropTypes.string.isRequired,
}
