import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Text } from './Text'

const StyledPageTitle = styled.h3`
  padding: 15px 0;

  @media (max-width: 992px) {
    padding: 0 0 15px 0;
  }
`

export const PageTitle = ({ children }) => (
  <StyledPageTitle>
    <Text isBold fontSize="1.6rem">
      {children}
    </Text>
  </StyledPageTitle>
)

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
