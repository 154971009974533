import styled from '@emotion/styled'

import { FlexBox } from '../FlexBox'

export const InfoWithImageFlexBox = styled(FlexBox)`
  @media (max-width: 992px) {
    flex-direction: column;

    & > div {
      display: flex;
      justify-content: center;
    }
  }
`
