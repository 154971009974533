import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useTemplate } from '../utils/custom-hooks/use-getters/useTemplate'

import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'
import { LinksList } from './LinksList'

const FooterContainer = styled.div`
  width: 100%;
  ${(p) => p.template}
  ${(p) => (p.fontFamily ? `font-family: '${p.fontFamily}';` : '')}
`

const CenterContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 25px 100px;

  & a:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    padding: 25px 25px;
    margin: 0;
  }
`

const MetaLinksContainer = styled.div`
  & a, & a:hover {
    color: #ffffff;
  }
`

const FooterTitleContainer = styled.div`
  border-bottom: 1px solid ${(p) => p.borderColor || '#ffffff'};
  padding-bottom: 20px;
`

const FooterContentContainer = styled.div`
  padding-top: 20px;
`

const FooterCopyrightContainer = styled.div`
  padding-top: 20px;
`

export const Footer = () => {
  const { footer } = useShallowEqualSelector((storeState) => storeState.moduleConfigReducer.associationConfig)
  const { props: templateProps } = useTemplate('Footer')

  const footerTemplate = css`
    background-color: ${templateProps.backgroundColor};

    & * {
      color: ${templateProps.color};
    }

    & a:hover {
      color: ${templateProps.color};
    }
  `

  return (
    <FooterContainer template={footerTemplate} fontFamily={templateProps.fontFamily}>
      <CenterContainer>
        <FooterTitleContainer borderColor={templateProps.color}>
          {footer.companyName}
        </FooterTitleContainer>
        <FooterContentContainer>
          <MetaLinksContainer>
            <LinksList
              fontColor={templateProps.color}
              links={footer.legalLinks.map((item) => ({
                url: item.url,
                title: item.name,
                open: item.open,
              }))}
              separator="|"
            />
          </MetaLinksContainer>
          <FooterCopyrightContainer
            dangerouslySetInnerHTML={{ __html: footer.textCopyright }}
          />
        </FooterContentContainer>
      </CenterContainer>
    </FooterContainer>
  )
}
