import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

export const MasterAnchor = ({
  children,
  to,
  target,
}) => {
  const history = useHistory()
  const isRelative = new URL(document.baseURI).origin === new URL(to, document.baseURI).origin
  if (isRelative) {
    if (typeof children === 'function') {
      return <Link to={to}>{children({ onGo: () => history.push(to), isRelative })}</Link>
    }
    return (
      <Link to={to}>{children}</Link>
    )
  }
  if (typeof children === 'function') {
    return <a href={to} target={target} rel="noreferrer">{children({ onGo: () => window.open(to, target), isRelative })}</a>
  }
  return (
    <a href={to} target={target} rel="noreferrer">{children}</a>
  )
}

MasterAnchor.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
}

MasterAnchor.defaultProps = {
  target: '',
}
