import { useEffect } from 'react'
import { PageContainer } from 'jmt-styled-components'
import styled from '@emotion/styled'
import { fetchComponentTemplatedResources } from 'jmt-fetch-component-resources'

import { useQuery } from '../utils/custom-hooks/useQuery'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'
import { useFlags } from '../utils/custom-hooks/use-getters/useFlags'

const LoginCompStyles = styled.div`
  & .btn.login__box__login_btn.btn-primary {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
  }

  & .form-group {
    & .d-block, .labelIcon {
      font-weight: bold;
    }
  }

  & a {
    text-decoration: none;
    color: var(--primaryColor);

    &:hover {
      text-decoration: none;
      color: var(--primaryColor);
    }
  }

  & .confirm-btn-container {
    & > a {
      text-decoration: none;
      color: var(--invertedPrimaryColor);

      &:hover {
        text-decoration: none;
        color: var(--invertedPrimaryColor);

      }
    }
  }
`

export const Auth = () => {
  const {
    oauthUrl,
    apiUrl,
    identityUrl,
    instance,
    language,
    short,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  const { loginExtensions } = useFlags()

  const dynamic = {}
  if (loginExtensions?.captchaSiteKey != null) {
    dynamic.captcha_sitekey = loginExtensions.captchaSiteKey
  }

  useEffect(() => {
    fetchComponentTemplatedResources({
      manageSubUrl: 'm-anage.com.component.login/m-anage.com.component.login',
      componentType: 'vue',
      version: '1.0.2.6',
      // version: 'dev',
      // isProdEnv: false,
    })
  }, [])

  const query = useQuery()
  const returnUrl = decodeURIComponent(query.get('returnUrl'))

  return (
    <PageContainer>
      <LoginCompStyles>
        <div
          id="manage-login"
          identity_url={identityUrl}
          oauth_url={oauthUrl}
          client_id=""
          api_url={apiUrl}
          instance={instance}
          language={language}
          event_short={short}
          return_url={returnUrl || '/'}
          context="Society"
          hide_title="true"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dynamic}
        />
      </LoginCompStyles>
    </PageContainer>
  )
}
