import styled from '@emotion/styled'
import {
  PageContainer, FlexBox, Centralizer, InfoWithImageFlexBox,
} from 'jmt-styled-components'

import { Button } from '../components/base/buttons/Button'
import { Card } from '../components/base/layouts/Card'

import errorImage from '../assets/images/error-general.png'
import { useTranslation } from '../utils/custom-hooks/useTranslation'
import { } from '../components/base/layouts/Centralizer'
import { } from '../components/base/layouts/customized/InfoWithImageFlexBox'

const ErrorTitle = styled.h2`
  padding-bottom: 10px;
`

const ErrorBody = styled.div`
  padding-bottom: 10px;

  a {
    color: ${(p) => p.theme.primaryColor};
    font-weight: bold;
  }
`

export const ErrorRoute = () => {
  const { t } = useTranslation()

  return (
    <PageContainer>
      <Centralizer>
        <Card padding="20px 30px">
          <InfoWithImageFlexBox>
            <div>
              <img src={errorImage} alt="Error Accord" />
            </div>
            <FlexBox dir="column">
              <ErrorTitle>{t('Error_Page-Info-Title')}</ErrorTitle>
              <ErrorBody
                dangerouslySetInnerHTML={{
                  __html: t('Error_Page-Info-Description').replace(/(?:\r\n|\r|\n)/g, '<br />'),
                }}
              />
              <ErrorBody
                dangerouslySetInnerHTML={{
                  __html: t('Error_Page-Info-Advice').replace(/(?:\r\n|\r|\n)/g, '<br />'),
                }}
              />
              <Button
                onClick={() => {
                  window.location.href = window.location.origin
                }}
              >
                {t('Error_Page-Info-Button-Title')}
              </Button>
            </FlexBox>
          </InfoWithImageFlexBox>
        </Card>
      </Centralizer>
    </PageContainer>
  )
}
