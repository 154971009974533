import {
  applyMiddleware, createStore, compose, combineReducers,
} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { rootReducer } from './rootReducer'

const middlewareEnhancer = applyMiddleware(thunkMiddleware)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const composedEnhancers = composeEnhancers(middlewareEnhancer)

export const store = createStore(
  combineReducers(rootReducer),
  undefined,
  composedEnhancers,
)
