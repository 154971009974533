import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Text } from './Text'

const StyledSectionTitle = styled.h4`
  padding: ${(p) => p.padding || '15px 0'};
`

export const SectionTitle = ({ children, padding }) => (
  <StyledSectionTitle padding={padding}>
    <Text isBold fontSize="1.3rem">
      {children}
    </Text>
  </StyledSectionTitle>
)

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
}

SectionTitle.defaultProps = {
  padding: '',
}
