import { Provider as ReduxProvider } from 'react-redux'

import { store } from './state/store'
import { AppInitializer } from './AppInitializer'

export const App = () => (
  <ReduxProvider store={store}>
    <AppInitializer />
  </ReduxProvider>
)
