import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useTemplate } from '../../utils/custom-hooks/use-getters/useTemplate'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'

const UserThumbnailContainer = styled.div`
  a {
    text-decoration: none;
  }
`

const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 3px solid ${(p) => p.theme.primaryColor};
`

const UserInitials = styled.span`
  text-transform: uppercase;
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  ${(p) => p.desktopTemplate}

  @media (max-width: 992px) {
    ${(p) => p.mobileTemplate}
  }
`

export const LoggedInUserThumbnail = () => {
  const userInitials = useShallowEqualSelector((storeState) => {
    const { User = {} } = storeState.appInfoReducer.summery
    return `${User?.firstname?.[0] || ''}${User?.surname?.[0] || ''}`
  })

  const ProfilePicturePath = useShallowEqualSelector((state) => {
    if (state.appInfoReducer?.summery?.User?.profile_pic_url) {
      return state.appInfoReducer.summery.User.profile_pic_url
    }
    return null
  })

  const { props: templateProps } = useTemplate('UserInitialsThumbnail')

  const desktopTemplate = css`
    color: ${templateProps.desktopColor};
    background-color: ${templateProps.desktopBackgroundColor};
  `

  const mobileTemplate = css`
    color: ${templateProps.mobileColor};
    background-color: ${templateProps.mobileBackgroundColor};
  `

  if (!userInitials) {
    return null
  }

  return (
    <UserThumbnailContainer>
      <Link to="/myprofile">
        {
          ProfilePicturePath
            ? (
              <ProfileImage
                src={ProfilePicturePath}
                height="100px"
                width="100px"
                alt=""
              />
            ) : (
              <UserInitials
                desktopTemplate={desktopTemplate}
                mobileTemplate={mobileTemplate}
              >
                {userInitials}
              </UserInitials>
            )
        }
      </Link>
    </UserThumbnailContainer>
  )
}
