import { PageContainer, PageTitle } from 'jmt-styled-components'

export const NotFound = () => (
  <PageContainer>
    <PageTitle>
      Page Not Found
      {/* TODO: replace with text-resource */}
    </PageTitle>
  </PageContainer>
)
