import { Loader } from 'jmt-styled-components'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { NotFound } from '../../routes/NotFound'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { PageContainer } from '../base/layouts/PageContainer'
import { AccessPermissionChecker } from '../permissions/AccessPermissionChecker'

export const AuthRoute = ({ children, routerConfig, ...routeProps }) => {
  const {
    token,
  } = useShallowEqualSelector((state) => state.userInfoReducer)
  if (token) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route {...routeProps}>
        <AccessPermissionChecker accessRequirements={routerConfig.accessRequirements}>
          {(isPermitted) => {
            if (isPermitted === 'permitted') {
              return children
            }
            if (isPermitted === 'loading') {
              return (
                <PageContainer>
                  <Loader />
                </PageContainer>
              )
            }
            return <NotFound />
          }}
        </AccessPermissionChecker>
      </Route>
    )
  }
  const { pathname = '', search = '' } = routeProps.location
  return <Redirect to={`/auth/login?returnUrl=${pathname}${search}`} />
}

AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
  routerConfig: PropTypes.shape({
    title: PropTypes.string.isRequired,
    accessRequirements: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }),
}

AuthRoute.defaultProps = {
  routerConfig: {},
}
