import { moduleConfigReducer } from './module-config/module-config-reducer'
import { userInfoReducer } from './user-info/user-info-reducer'
import { appInfoReducer } from './app-info/app-info-reducer'
import { conditionsCheckerReducer } from '../components/conditions-checker/state/conditions-checker-reducer'
import { profileModalReducer } from '../components/profile-modal/state/profile-modal-reducer'
import { routerReducer } from './router/router-reducer'

export const rootReducer = {
  moduleConfigReducer,
  userInfoReducer,
  appInfoReducer,
  conditionsCheckerReducer,
  profileModalReducer,
  routerReducer,
}
