import styled from '@emotion/styled'
import { useFlags } from '../../utils/custom-hooks/use-getters/useFlags'
import { useIsMobile } from '../../utils/custom-hooks/useIsMobile'

const Iframe = styled.iframe`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
`

export const Brevo = () => {
  const { brevoNewsletter } = useFlags()
  const isMobile = useIsMobile()
  return (
    <Iframe
      title="brevo-newsletter-iframe"
      width={isMobile ? brevoNewsletter.mobileWidth : brevoNewsletter.desktopWidth}
      height={isMobile ? brevoNewsletter.mobileHeight : brevoNewsletter.desktopHeight}
      src={brevoNewsletter.iframeSrc}
      frameBorder="0"
      scrolling="auto"
      allowFullScreen
    />
  )
}
