import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTranslation } from '../../utils/custom-hooks/useTranslation'
import { Card } from '../base/layouts/Card'

const CardBody = styled.div`
  padding: 20px 25px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > h3 {
    color: ${(p) => p.theme.primaryColor};
    margin-bottom: 1rem;
  }
  & > p {
    padding-bottom: 1rem;
  }
  & > a {
    margin-top: auto;
  }
`
export const FreeUiRssBox = ({ uiRss }) => {
  const { t } = useTranslation()
  const freeUiRss = t(uiRss)

  if (freeUiRss === '' || freeUiRss == null || uiRss === freeUiRss) {
    return null
  }

  return (
    <Card wrapperAdditionalStyles="margin: 15px 0;">
      <CardBody>
        <div dangerouslySetInnerHTML={{ __html: freeUiRss }} />
      </CardBody>
    </Card>
  )
}

FreeUiRssBox.propTypes = {
  uiRss: PropTypes.string,
}

FreeUiRssBox.defaultProps = {
  uiRss: '',
}
