import { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { AccessPermissionChecker } from './permissions/AccessPermissionChecker'

export const SubRoutesButtons = ({ subRoutes, children }) => {
  const { pathname } = useLocation()
  return subRoutes.map((route) => {
    const url = `${pathname}${route.path}`
    if (route.props.accessRequirements) {
      return (
        <AccessPermissionChecker accessRequirements={route.props.accessRequirements} key={url}>
          {(isPermitted) => {
            if (isPermitted === 'permitted') {
              return children({ url, name: route.name, title: route.props.title })
            }
            return null
          }}
        </AccessPermissionChecker>
      )
    }
    return (
      <Fragment key={url}>
        {children({ url: `${pathname}${route.path}`, title: route.props.title, name: route.name })}
      </Fragment>
    )
  })
}
