import { ManageFetch } from '../../api/manage-fetch'
import { SET_APP_INFO } from './app-info-types'

const setFetching = (key, status) => (dispatch, getState) => {
  const { fetching } = getState().appInfoReducer
  const nextFetching = { ...fetching, [key]: status }
  dispatch({ type: SET_APP_INFO, payload: { fetching: nextFetching } })
}

export const fetchSummeryInfo = (update) => (dispatch, getState) => {
  const { summery, fetching } = getState().appInfoReducer
  const { eventGroups } = getState().moduleConfigReducer.associationConfig

  if (fetching.summery === 'fetching') {
    return
  }

  if (!Object.keys(summery).length || update) {
    dispatch(setFetching('summery', 'fetching'))
    ManageFetch.request`${(p) => p.apiUrl}/api/MyAssociation/Summary`({
      query: {
        shortName: (p) => p.short,
        eventGroups: eventGroups.join(','),
      },
    }).then((res) => {
      const { PageEvents, ...rest } = res.data
      const reversedPageEvents = PageEvents.reverse()
      dispatch({
        type: SET_APP_INFO,
        payload: { summery: { ...rest, PageEvents: reversedPageEvents } },
      })
      dispatch(setFetching('summery', 'done'))
    }).catch((err) => {
      console.log({ err })
      dispatch(setFetching('summery', 'error'))
    })
  }
}

export const fetchMembershipInfo = (update) => (dispatch, getState) => {
  const { membership, fetching } = getState().appInfoReducer

  if (fetching.membership === 'fetching') {
    return
  }

  if (!Object.keys(membership).length || update) {
    dispatch(setFetching('membership', 'fetching'))
    ManageFetch.request`${(p) => p.apiUrl}/api/MyAssociation/Membership`({
      query: {
        shortName: (p) => p.short,
      },
    }).then((res) => {
      dispatch({
        type: SET_APP_INFO,
        payload: { membership: { ...res.data.MyMemberships[0] } },
      })
      dispatch(setFetching('membership', 'done'))
    }).catch((err) => {
      console.log({ err })
      dispatch(setFetching('membership', 'error'))
    })
  }
}

export const setMembersDirectoryVisibilityAction = ({ nextValue }) => (dispatch, getState) => (
  new Promise((resolve, reject) => {
    const { membership } = getState().appInfoReducer
    ManageFetch.request`${(p) => p.apiUrl}/v2/Membership/MemberDirectoryForMember`({
      method: 'POST',
      query: {
        societyShort: (p) => p.short,
      },
      body: {
        showInDirectory: nextValue,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      dispatch({
        type: SET_APP_INFO,
        payload: { membership: { ...membership, ShowInDirectory: nextValue } },
      })
      resolve()
    }).catch((err) => {
      console.log('fail', { err })
      reject()
    })
  })
)
