import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Loader,
  PageContainer,
} from 'jmt-styled-components'

import { ConditionsSlider } from '../../components/conditions-checker/ConditionsSlider'
import { explicitPermissionsCheckAction } from '../../components/conditions-checker/state/conditions-checker-actions'

export const PermissionsChecker = () => {
  const dispatch = useDispatch()
  const params = new URLSearchParams(document.location.search)
  const encodedAction = params.get('action')

  const { modulePermissions, target } = JSON.parse(atob(encodedAction))

  const [isConditionChecked, setIsConditionChecked] = useState(false)

  useEffect(() => {
    if (!target) {
      window.close()
    } else {
      dispatch(explicitPermissionsCheckAction({
        modulePermissions,
        onPermissionsMissing: () => {
          setIsConditionChecked(true)
        },
        onPermissionsGiven: () => {
          window.location.assign(target)
        },
        onFail: () => window.close(),
      }))
    }
  }, [])

  if (!isConditionChecked) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ConditionsSlider
        onFinish={() => {
          window.location.assign(target)
        }}
      />
    </PageContainer>
  )
}
