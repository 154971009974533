import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { ManageFetch } from '../api/manage-fetch'
import { ErrorRoute } from './ErrorRoute'
import { MaintenanceRoute } from './MaintenanceRoute'

export const BasicRoutes = ({ children }) => {
  const history = useHistory()

  useEffect(() => {
    ManageFetch.setConfig({
      interceptedErr: [
        {
          name: 'server-not-reachable',
          action: (error) => {
            console.log({ error })
            if (error.name === 'TypeError') {
              const errorInfo = JSON.parse(error.message)
              console.log('caught maintenance error', errorInfo)
              history.push('/maintenance')
            }
          },
        },
        {
          name: 'catch-maintenance',
          action: (error) => {
            console.log({ error })
            if (error.name === 'NoSuccess') {
              const errorInfo = JSON.parse(error.message)
              if (errorInfo.response.status === 503) {
                console.log('caught maintenance error')
                history.push('/maintenance')
              }
            }
          },
        },
        {
          name: 'catch-error',
          action: (error) => {
            if (error.name === 'NoSuccess') {
              const errorInfo = JSON.parse(error.message)
              if (
                errorInfo.response.status >= 302
                && errorInfo.response.status !== 503
              ) {
                console.log('caught general error')
                history.push('/error')
              }
            }
          },
        },
      ],
    })
  }, [])

  return (
    <>
      {children}
      <Route path="/error" exact>
        <ErrorRoute />
      </Route>
      <Route path="/maintenance" exact>
        <MaintenanceRoute />
      </Route>
    </>
  )
}

BasicRoutes.propTypes = {
  children: PropTypes.node,
}

BasicRoutes.defaultProps = {
  children: null,
}
