import { createRoot } from 'react-dom/client'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { App } from './App'
import { store } from './state/store'
import { setNavigatedToExternalAction } from './state/router/router-actions'

const { REACT_APP_ENV } = process.env;

(function () {
  window.onpageshow = function (event) {
    if (event.persisted) { // coming from back nav
      const { pathname } = window.location
      if (pathname === '/permissions-checker') {
        window.close()
      } else {
        window.location.reload()
      }
    }
  }
}());

// required to fix an issue when firefox tab becomes inactive after navigating to external page
(function () {
  window.onfocus = () => {
    const { sentToExternal } = store.getState().routerReducer
    if (
      navigator.userAgent.search('Firefox') > 1
      && sentToExternal
    ) {
      store.dispatch(setNavigatedToExternalAction(false))
      window.location.reload()
    }
  }
}())

i18next
  .use(initReactI18next)
  .init({
    resources: {
      'en-GB': {
        global: {
          'Error_Page-Info-Advice': 'Please try again later.',
          'Error_Page-Info-Button-Title': 'Reload',
          'Error_Page-Info-Description': 'We have encountered an error.',
          'Error_Page-Info-Title': 'Error Accord',
          'Maintenance_Page-Info-Advice': 'Please try again later.',
          'Maintenance_Page-Info-Button-Title': 'Reload',
          'Maintenance_Page-Info-Description': 'This page is currently undergoing maintenance. We will be back shortly.',
          'Maintenance_Page-Info-Title': 'Maintenance',
        },
      },
    },
    fallbackLng: 'en-GB',
    debug: REACT_APP_ENV === 'development',
    interpolation: { escapeValue: false },
    react: {
      bindI18nStore: 'added',
    },
  })

const root = createRoot(document.getElementById('root'))
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <App />,
)
