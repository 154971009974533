import PropTypes from 'prop-types'
import { Button as JmtButton } from 'jmt-styled-components'
import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

export const Button = ({ ...props }) => {
  const template = useTemplate('Button')

  return (
    <JmtButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      template={props.template || template.props}
    />
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  showLoader: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  template: PropTypes.shape(),
}

Button.defaultProps = {
  children: '',
  showLoader: false,
  template: null,
}
