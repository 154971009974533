import { useDispatch } from 'react-redux'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { Modal } from '../base/layouts/Modal'
import { ConditionsSlider } from './ConditionsSlider'
import { hideConditionsModal } from './state/conditions-checker-actions'

export const ConditionsChecker = () => {
  const {
    showModal,
    onSuccess,
    onFail,
  } = useShallowEqualSelector((storeState) => storeState.conditionsCheckerReducer)

  const dispatch = useDispatch()

  if (!showModal) {
    return null
  }

  return (
    <Modal
      isVisible
      onClose={() => {
        onFail()
        dispatch(hideConditionsModal())
      }}
    >
      <div>
        <ConditionsSlider
          onFinish={() => {
            onSuccess()
            dispatch(hideConditionsModal())
          }}
        />
      </div>
    </Modal>
  )
}
