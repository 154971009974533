import {
  SET_CONDITION_CHECKER,
  RESET_CONDITION_CHECKER,
} from './conditions-checker-types'

const initialState = {
  requiredActions: [],
  showModal: false,
  onSuccess: null,
  onFail: null,
  checkedAction: null,
}

export const conditionsCheckerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONDITION_CHECKER:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_CONDITION_CHECKER:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
