import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { BasicRoutes } from './BasicRoutes'

export const InitialRouterLoad = () => (
  <Router>
    <Switch>
      <BasicRoutes>
        <Route path="/" />
      </BasicRoutes>
    </Switch>
  </Router>
)
