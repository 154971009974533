import styled from '@emotion/styled'

export const Centralizer = styled.div`
  width: fit-content;
  position: absolute;
  margin-left: auto; 
  margin-right: auto;
  left: 0; 
  right: 0; 

  @media (max-width: 992px) {
    position: static;
  }
`
