import { useHistory, useLocation } from 'react-router-dom'
import { Button, Icon } from 'jmt-styled-components'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'
import { goBackRouterAction, setRouterAction } from '../state/router/router-actions'
import { useTranslation } from '../utils/custom-hooks/useTranslation'

const Rotator = styled.span`
  position: relative;
  left: -5px;
  padding-right: 10px;
  & svg {
    transform: rotate(-90deg);
  }
`

const findPageByPath = (pages, path) => {
  const [_, current, ...nested] = path.split('/')
  const lastPage = pages.find((page) => page.path === `/${current}`)
  if (nested.length) {
    return findPageByPath(lastPage.subRoutes, `/${nested.join('/')}`)
  }
  return lastPage
}

export const GoBackButton = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRouterAction({ pathname }))
  }, [pathname])

  const { history: browserHistory } = useShallowEqualSelector((storeState) => storeState.routerReducer)
  const { pages } = useShallowEqualSelector((storeState) => storeState.moduleConfigReducer.associationConfig)
  const { t } = useTranslation()

  if (browserHistory.length <= 1) {
    return null
  }

  const lastPage = findPageByPath(pages, browserHistory[1])

  return (
    <div>
      <Button
        onClick={() => {
          dispatch(goBackRouterAction())
          history.goBack()
        }}
        template={css`
          background-color: transparent;
          padding: 0;
          margin: 0;
        `}
      >
        <Rotator>
          <Icon icon="fa-level-up-alt" color="#000000" />
        </Rotator>
        {t(`${lastPage?.props?.title || lastPage.name}_Page-Title`)}
      </Button>
    </div>
  )
}
